// TODO Implement dark/light themes

.App {
  text-align: left;

  .Body {
    margin: .5em;
    margin-bottom: 2em;

    .Input {
      .InputContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .InputContent {
          flex: 1 1 10px;
          margin: .69vmax;
        }

        @media (max-width: 600px) {
          .InputContent {
            flex-basis: 100%;
          }
        }

        .Radio {
          margin: 1vmax 0 1vmax 0;
        }

        .FileInput {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: center;
          min-height: 5vmax;
          border-radius: 10px;
          box-shadow: 0 0 0 1px;
          padding: 1vmax 0;
          border: 1px solid transparent;
          cursor: pointer;

          button {
            margin: .5vmax 0 .5vmax 0;
          }

          input {
            display: none;
          }
        }

        .TextInput {
          width: -moz-available;
          height: 15vmax;
          border-radius: 10px;
          box-shadow: 0 0 0 1px;
          border: 1px solid transparent;
          resize: vertical;
          background-color: var(--background-color);
        }
      }

      .Button {
        margin: 1vmax 0 1vmax 0;
        text-align: center;

        svg {
          animation: spin 2s infinite;
          margin-left: 0.5em;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .Output {
      .Section {
        margin: 0 .5em 0 .5em;

        .Title {
          cursor: pointer;

          svg {
            transition: transform .1s ease-in-out;
          }
        }

        h4 {
          margin: 0 .5em 0 .5em;
        }

        .Table {
          margin: .5em 0 1em 0;

          table {
            table-layout: auto;
            width: 100%;
            border-spacing: 0;
            border-collapse: inherit;
            border-radius: 10px;
            border: 1px solid;
          }

          table thead th {
            border-bottom: 1px solid;
          }

          table tbody td:not(:last-child) {
            border-right: 1px solid;
          }

          table tbody tr:not(:last-child)>td {
            border-bottom: 1px solid;
          }

          table thead th:not(:last-child),
          table tbody td:not(:last-child) {
            border-right: 1px solid;
          }

          th,
          td {
            padding: 0 .5em 0 .5em;
            /* TODO implement ellipses and click on td to expand the content */
          }
        }

        .Card {
          margin: .5em 0 1em 0;
          padding: .5em;
          overflow-wrap: break-word;
          border-spacing: 0;
          border-collapse: inherit;
          border-radius: 10px;
          border: 1px solid;
        }

        .List {
          overflow-wrap: break-word;
        }
      }

      .Section.collapsed {
        .Title .collapse {
          transform: scale(-1, -1);
          transition: transform .1s ease-in-out;
        }
      }
    }
  }

  .Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 2em;
    background-image: linear-gradient(to top, rgb(255, 255, 255), 90%, rgba(255, 255, 255, 0));

    div {
      margin: 10px 10px 0 10px;
    }

    svg {
      cursor: pointer;
      color: var(--text-color, #1a1a1a);
    }

    svg:hover {
      opacity: 50%;
    }
  }
}